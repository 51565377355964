import React, { useState } from 'react';
import {useTranslation} from "react-i18next"
const Contact = ({}) => {
    
            const [availability, setAvailability] = useState([])
            const [t, i18n] = useTranslation('common')
            return (  
                <>
                <br /> <br /> <br />    
                <div style={{padding:'10px',margin:'5px', zIndex:11, width: '300px', left:10,
                    opacity: 1, backgroundColor:'#ffeee4',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'}} >
                    <br/><br/> 
                 <b>{t('common.list10000', {framework:'React'})}</b><br />
                <ul>
                    <li>{t('common.list10001', {framework:'React'})}</li>
                    <li><a href='/booking'>{t('common.list10002', {framework:'React'})}</a></li>
                    <li>{t('common.list10003', {framework:'React'})}</li>
                    <li>{t('common.list10004', {framework:'React'})}</li>
                    <li>{t('common.list10005', {framework:'React'})}</li>
                    <li>{t('common.list10006', {framework:'React'})}</li>
                 </ul>
                 <br /><br />
                </div>
                </>
            );
    }
    export default Contact
