import React, { useState } from 'react';
import {useTranslation} from "react-i18next"
//import Card from '@mui/material/Card';

const Activities = ({}) => {
    /**<div class="card border-dark mb-3" style="max-width: 18rem;">
                    <div class="card-header">Header</div>
                    <div class="card-body text-dark">
                        <h5 class="card-title">Dark card title</h5>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                </div> */
            const [availability, setAvailability] = useState([])
            const [t, i18n] = useTranslation('common')
            return (   
                <>
                    <br /><br />  
                    <div style={{ 
        padding:'10px',margin:'5px', zIndex:11, width: '300px', left:10,
        opacity: 1, backgroundColor:'white',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'
      }} >
                    <b>{t('common.list100', {framework:'React'})}</b>
                    <ul>
                        <li>{t('common.list101', {framework:'React'})}</li>
                        <li>{t('common.list102', {framework:'React'})}</li>
                        <li>{t('common.list103', {framework:'React'})}</li>
                        <li>{t('common.list104', {framework:'React'})}</li>
                        <li>{t('common.list105', {framework:'React'})}</li>
                        <li>{t('common.list106', {framework:'React'})}</li>
                        <li>{t('common.list107', {framework:'React'})}</li>
                        <li>{t('common.list108', {framework:'React'})}</li>
                        <li>{t('common.list109', {framework:'React'})}</li>
                        <li>{t('common.list110', {framework:'React'})}</li>
                        <li>{t('common.list111', {framework:'React'})}</li>
                        <li><a href='http://www.etelapaa.fi' target='0'>{t('common.list112', {framework:'React'})}</a></li>
                    </ul>

                    
                <br /><br />
                  
                </div>    
                    <div style={{ 
        padding:'10px',margin:'5px', zIndex:11, width: '500px', left:10,
        opacity: 1, backgroundColor:'white',border: '1px solid #12343b', fontSize:'18px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'
      }} >
                    <br/>
                    <div style={{zIndex:1000, backgroundColor:'white'}}><iframe src="https://www.google.com/maps/d/u/0/embed?mid=1Akoxc5gEb97esVANAmsFDlRywZMvqdg&ehbc=2E312F" width="100%" height="480"></iframe></div>
                    <br />
                    </div>
                    
                </>
            );
    }
    export default Activities
