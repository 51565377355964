import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import kuva1 from '../pics/IMG_20221016_123236.png'
import kuva2 from '../pics/IMG_20221016_121812.png'
import kuva3 from '../pics/IMG_20221016_121927.png'
import kuva4 from "../pics/IMG_20221202_211854.png"
import kuva5 from "../pics/IMG_20221202_213302.png"
import kuva6 from "../pics/IMG_20221203_122452.png"
import kuva7 from "../pics/IMG_20221203_122032.png"
import kuva8 from "../pics/IMG_20221203_121909.png"
import kuva9 from "../pics/IMG_20221203_121850.png"
import kuva10 from "../pics/IMG_20221203_121535.png"
import kuva11 from "../pics/IMG_20221203_121510.png"
import kuva12 from "../pics/IMG_20221203_121507.png"
import kuva13 from "../pics/IMG_20221203_121506.png"
import kuva14 from "../pics/IMG_20221203_121358.png"
import kuva16 from "../pics/IMG_20221202_195849.png"
import kuva17 from "../pics/IMG_20221016_123220.png"
import kuva18 from "../pics/IMG_20221016_123202.png"
import kuva19 from "../pics/IMG_20221016_121918.png"
import kuva21 from "../pics/IMG_20221016_121757.png"
import { CenterFocusStrong } from '@material-ui/icons';


const Carouseli = ({care,walking,ocare,owalking, walkers,handlers,animals }) => {
    
        return (
            <>
            <br /><br />
            <div style={{ padding:'10px',margin:'5px', zIndex:11, width: '85%', left:10, opacity: 1, backgroundColor:'white',border: '5px solid #12343b', fontSize:'18px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'}}   align='center'>
                
            <Carousel>
                <div style={{width: '100%', zIndex:100}}>
                    <img alig='center' src={kuva1} />
                    <p className="legend">Ulkoa</p>
                </div>
                <div style={{width: '100%', zIndex:100}}>
                    <img src={kuva2}/>
                    <p className="legend">Keittiö</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva3}/>
                    <p className="legend">Yläkerta</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva4}/>
                    <p className="legend">Yläkerta</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva5}/>
                    <p className="legend">Olohuone</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva6}/>
                    <p className="legend">Leikkipuisto</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva7}/>
                    <p className="legend">Järvi</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva8}/>
                    <p className="legend">Mökki ulkoa</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva9}/>
                    <p className="legend">Mökiltä järvelle</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva10}/>
                    <p className="legend">Sauna</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva11}/>
                    <p className="legend">Terassi</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva12}/>
                    <p className="legend">Terassi</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva13}/>
                    <p className="legend">Terassi</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva14}/>
                    <p className="legend">Polttopuut</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva16}/>
                    <p className="legend">Keittiö</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva17}/>
                    <p className="legend">Mökki ulkoa</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva18}/>
                    <p className="legend">Mökki ulkoa</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva19}/>
                    <p className="legend">Yläkerta</p>
                </div>
                <div style={{width: '100%', zIndex:100}}   >
                    <img src={kuva21}/>
                    <p className="legend">Olohuone</p>
                </div>
            </Carousel>
            </div>
            </>
        );
    }
    export default Carouseli
