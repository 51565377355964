import React, { useState } from 'react';

const Panorama = ({}) => {
    
            const [availability, setAvailability] = useState([])
            return (  
                <>
                <br /> <br /> <br />            
                <div style={{ 
                    padding:'10px',margin:'5px', zIndex:11, width: '300px', left:10,
                    opacity: 1, backgroundColor:'white',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'
                  }} >
                                <br/>
                                Tähän tulee 360 kuvia
                              
                            </div>
                            </>
            )
            
    }
    export default Panorama
