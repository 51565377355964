import React, { useState } from 'react'
import Vidala from "../pics/Vid20221203133906-1.m4v"
import Vidyla from "../pics/Vid20221203133524-1.m4v"
import Thumbyla from "../pics/IMG_20221202_211854.png"
import Thumbala from "../pics/IMG_20221202_213302.png"
import styled from "styled-components";
import ReactPlayer from "react-player";
const Videos = ({}) => {
    
    const [availability, setAvailability] = useState([])
    const [isVideoLoaded, setIsVideoLoaded] = useState(false);
    const onLoadedData = () => {
    setIsVideoLoaded(true)
  }

  const Container = styled.div`
  width: 100%;
  position: relative;
  .video-thumb {
    position: absolute;
    top: 0; 
    left: 0;
    height: 100%;
    width: 100%;
    transition: opacity 400ms ease 0ms;
  }
  .tiny {
    filter: blur(20px);
    transform: scale(1.1);
    transition: visibility 0ms ease 400ms;
  }
`;
    return (  
      <>
      <br/><br/>             
        <div style={{ 
            padding:'10px',margin:'5px', zIndex:11, width: '630px', left:10,
            opacity: 1, backgroundColor:'white',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'
            }} >
                        <br/>
                        <Container>
       <br />
       <br />
        <img
          src={Thumbala}
          className="video-thumb tiny"
          alt="thumb"
          style={{ width:'600px', opacity: isVideoLoaded ? 0 : 1 }}
        />
        <div style={{ width:'600px',  opacity: isVideoLoaded ? 1 : 0 }}>
          <ReactPlayer
            url={Vidala}
            playing={true}
            controls={true}
            loop={true}
            muted={true}
            playsinline={true}
            onReady={onLoadedData}
          />
        </div>
      </Container>
      <Container>
        
        <img
          src={Thumbyla}
          className="video-thumb tiny"
          alt="thumb"
          style={{ width:'85%', opacity: isVideoLoaded ? 0 : 1 }}
        />
        <div style={{width:'85%',  opacity: isVideoLoaded ? 1 : 0 }}>
          <ReactPlayer
            url={Vidyla}
            playing={true}
            controls={true}
            loop={true}
            muted={true}
            playsinline={true}
            onReady={onLoadedData}
          />
        </div>
      </Container>
      <br />
    </div>
    </>
    )
}
    export default Videos
