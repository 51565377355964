import React, { useState, useEffect, Suspense} from 'react'
import { useQuery, useApolloClient, useSubscription } from '@apollo/client'
import {useTranslation} from "react-i18next"
import Activities from './components/Activities'
import Prices from './components/Prices'
import Pics from './components/Pics'
import Panorama from './components/Panorama'
import Videos from './components/Videos'
import Contact from './components/Contact'
import Banner from './components/Banner'
//import { BackgroundImage, Center, Text, Box } from '@mantine/core'
import UserForm from './components/UserForm'
import Users from './components/Users'
import Carouseli from './components/Carouseli'
import Booking from './components/Booking'
import UserActivation from './components/UserActivation'
import LoginForm from './components/LoginForm'
import Logout from './components/Logout'
import UserRegistration from './components/UserRegistration'
import Home from './components/Home'
import Gdpr from './components/Gdpr'
import Faq from './components/Faq'
import User from './components/User'
import Admin from './components/Admin'
import Header from './components/Header'
import Headerpublic from './components/Headerpublic'
import Headeradmin from './components/Headeradmin'
import Info from './components/Info'
import Termsofuse from './components/Termsofuse'
import Instructions from './components/Instructions'
import taustakuva from './pics/etu.png'
import taustamobile from './pics/taustamobile.png'
import RouteChangeTracker from './components/RouteChangeTracker'
import { ALL_ORGANS, ALL_COMMENTS, ALL_USERS, ALL_LINKS, ALL_VOTES, ALL_INITIATIVES, ALL_MUNICIPALITIES, ALL_SECTIONS,  ORGAN_ADDED,  ALL_PROPOSALS, ALL_MEETINGS } from './queries'
import Footer from './components/Footer'
//import background from "./pics/taustapieni_mv.png";
import tavi from "./pics/2.png";
import './App.css'
import WordSection from './components/WordSection'
import ForgetPassword from './components/ForgetPassword'
import NewPassword from './components/NewPassword'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom"
//import { MuiThemeProvider, createMuiTheme } from 'material-ui/core/styles'
import {
  Container,
  Button,
  AppBar,
//makeStyles,
//fade
} from '@material-ui/core'
//import ReactGA from 'react-ga'
//ReactGA.initialize('UA-198353084-1')
import TagManager from 'react-gtm-module'

const Notify = ({ errorMessage }) => {
  if ( !errorMessage ) {
    return null
  }

  return (
    <div style={{color: 'red'}}>
      {errorMessage}
    </div>
  )
}

const App = () => {
  //let loggedin = ''
  const [user, setUser] = useState(null) 
  const [usertype, setUsertype] = useState(null) 
  const [log, setLogout] = useState(null) 
  
  /***
   * #FFFFFF
   * #FBA92C
   * #9E9E9E
   * #565656
   * #141414
   * TAI
   * #C8B7A6
   * #7D94B5
   * #C29591
   * #703F37
   * #B6C199
   * 
   */

  const login = (user) => {
    setUser(user)
    notify(`tervetuloa ${user}`)
    //setMessage(`tervetuloa ${user}`)
  }
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  useEffect(() => {
    TagManager.initialize({ gtmId: 'GTM-WCF466Q' });
  }, [])
  
  const [token, setToken] = useState(null)
  const [errorMessage, setErrorMessage] = useState(null) 
  const organsw = useQuery(ALL_ORGANS, {
    pollInterval: 100000  })

  const users = useQuery(ALL_USERS, {
    pollInterval: 100000  })

  let userss=[]
  if(users.data){
    userss=users.data.allUsers
  }
  const votes = useQuery(ALL_VOTES, {
    pollInterval: 100000  })

  const client = useApolloClient()
  //const results= Object.values(organs.data)
  //console.log(organs.data.allOrgans)

  const updateCacheWith = (addedOrgan) => {
    const includedIn = (set, object) => 
      set.map(p => p.id).includes(object.id)  

    const dataInStore = client.readQuery({ query: ALL_ORGANS })
    if (!includedIn(dataInStore.allOrgans, addedOrgan)) {
      client.writeQuery({
        query: ALL_ORGANS,
        data: { allOrgans : dataInStore.allOrgans.concat(addedOrgan) }
      })
    }   
  }

  useSubscription(ORGAN_ADDED, {
    onSubscriptionData: ({ subscriptionData }) => {
      const addedOrgan = subscriptionData.data.organAdded
      notify(`${addedOrgan.name} added`)
      updateCacheWith(addedOrgan)
    }
  })

  useEffect(() => {
    document.title = "Lokosmaja Siikaisten Eteläpäässä"
    const token = localStorage.getItem('dogi-user-token')
    if ( token ) {
      setToken(token)
    }
    const useri = localStorage.getItem('usertype')
    if ( useri) {
      setUsertype(useri)
    }
    const email = localStorage.getItem('email')
    if(email){
      setUser(localStorage.getItem('email'))
    }
   /* ReactGA.event({
      category: 'Open App',
      action: 'Opening kunta.live',
      label: 'Opening'
    })
    */
  }, [])
  
  const notify = (message) => {
    setErrorMessage(message)
    setTimeout(() => {
      setErrorMessage(null)
    }, 5000)
  }
//"repeat|repeat-x|repeat-y|no-repeat|initial|inherit"
  if  (window.innerWidth < 900) {
  return (
      <Suspense fallback="loading">
      <div  style={{ 
          width:'99%',
          position: 'absolute', 
          font: 'Verdana',
          //backgroundColor: '#fefbe9',
          //'#52ab98',
          //#c8d8e4
          backgroundRepeat: 'no-repeat',
          backgroundImage:`url(${taustakuva})`,
          backgroundAttachment: 'fixed',
          //borderRadius: '16px',
          //border: "2px solid #73AD21"
        }}>
        
      <Container>
        <Router>

          <AppBar position="static" style={{ right:'0px', position: 'fixed', top:'25px', bottom: 0,  height:'0px', backgroundColor:'#266150',zIndex:'5'}} height='10px'>
              <Headerpublic style={{zIndex:8}} />
              <div  style={{right:'5px', position: 'fixed',top:'2%',  height:'20px'}} height='20px' position align='right' z-index='9  '>
                <Button style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black',fontSize: "11px" }} size='small' color="primary" onClick={() => i18n.changeLanguage('fi')}>fi</Button>
                <Button style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black',fontSize: "11px" }} size='small' color="primary"  onClick={() => i18n.changeLanguage('en')}>en</Button>
                <Button variant="outlined" style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black', fontSize: "11px" }} size='small' color="primary"  onClick={() => i18n.changeLanguage('sv')}>sv</Button>
              </div>
          </AppBar>
          <br />
          <br />
          <br />
          <Notify errorMessage={errorMessage} />
          <Switch>
            <Route path="/home">
              <br />
              <Home />
            </Route>
            <Route path="/faq">
              <Faq />
            </Route>
            <Route path="/contact">
              <Contact />
            </Route>
            <Route path="/videos">
              <Videos />
            </Route>
            <Route path="/panorama">
              <Panorama />
            </Route>
            <Route path="/prices">
              <Prices />
            </Route>
            <Route path="/gdpr">
              <Gdpr />
            </Route>
            <Route path="/activities">
              <Activities />
            </Route>
            <Route path="/booking">
              <Booking />
            </Route>
            <Route path="/pics">
              <Pics  />
              <Carouseli />
            </Route>
            <Route path="/register">
            <UserRegistration setError={notify} />
            </Route>
            <Route path="/forgetpassword">
              <ForgetPassword setError={notify} />
            </Route>
            <Route path="/newpassword/:id">
              <NewPassword setError={notify} />
            </Route>
            <Route path="/activation/:id">
              <UserActivation setError={notify}  />
            </Route>
            <Route path="/info">
              <Info  />
            </Route>
            <Route path="/termsofuse">
              <Termsofuse   />
            </Route>
            <Route path="/instructions">
              <Instructions   />
            </Route>
            <Route path="/login">
              {(token) ? <Redirect to="/" /> : <LoginForm setUsertype={setUsertype} onLogin={login} setError={notify} log={log} setLogout={setLogout} logout={0} setToken={setToken} client={client}/>
              }
            </Route>
            <Route path="/login/:id">
              {(log !== null) ? <Redirect to="/" /> : <LoginForm setUsertype={setUsertype} onLogin={login} setError={notify} log={log} setLogout={setLogout} logout={1} setToken={setToken} client={client}/>
              }
            </Route>
            <Route path="/logout">
              {(log !== null) ? <Redirect to="/" /> : <Logout setUsertype={setUsertype}  onLogin={login} setError={notify} log={log} setLogout={setLogout} logout={1} setToken={setToken} client={client}/>
            }  
            </Route>
            <Route path="/">
              <Home/>
            </Route>
          </Switch>
          <br /><br /><br /><br />
          <Footer/>
        </Router>
      </Container>
      </div>
      </Suspense>
    )
    }
    /**
 * 
 * 
 * Sand Tan: #e1b382

Sand Tan Shadow: #c89666

Night Blue: #2d545e

Night Blue Shadow: #12343b
<img src={tavi} width='85%'/>
 */

    return (
      <>
        <Suspense fallback="loading">
        
        <div  style={{ 
          width:'99%',
          position: 'absolute', 
          font: 'Verdana',
          //backgroundColor: '#fefbe9',
          //'#52ab98',
          //#c8d8e4
          backgroundRepeat: 'no-repeat',
          backgroundImage:`url(${taustakuva})`,
          backgroundAttachment: 'fixed',
          //borderRadius: '16px',
          //border: "2px solid #73AD21"
        }}>
          
        <Container>
          <Router>
            <AppBar position="static" style={{ right:'0px', position: 'fixed', top:'25px', bottom: 0,  height:'0px', backgroundColor:'#266150',zIndex:'5'}} height='10px'>
              <Headerpublic style={{zIndex:8}} />
              <div  style={{right:'15%', position: 'fixed',top:'1%',  height:'20px'}} height='20px' position align='right' z-index='9  '>
                <Button style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black',fontSize: "11px" }} size='small' color="primary" onClick={() => i18n.changeLanguage('fi')}>fi</Button>
                <Button style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black',fontSize: "11px" }} size='small' color="primary"  onClick={() => i18n.changeLanguage('en')}>en</Button>
                <Button variant="outlined" style={{height:'20px', backgroundColor: "#ff5700", color:"#692705",border: '1px solid black', fontSize: "11px" }} size='small' color="primary"  onClick={() => i18n.changeLanguage('sv')}>sv</Button>
              </div>
            </AppBar>
            <br />
            <br />
            <br />
            <Notify errorMessage={errorMessage} />
            <Switch>
              <Route path="/home">
                <br />
                <Home />
              </Route>
              <Route path="/faq">
                <Faq />
              </Route>
              <Route path="/contact">
                <Contact />
              </Route>
              <Route path="/videos">
                <Videos />
              </Route>
              <Route path="/panorama">
                <Panorama />
              </Route>
              <Route path="/prices">
                <Prices />
              </Route>
              <Route path="/gdpr">
                <Gdpr />
              </Route>
              <Route path="/activities">
                <Activities />
              </Route>
              <Route path="/booking">
                <Booking />
              </Route>
              <Route path="/pics">
                <br />
                <Carouseli />
              </Route>
              <Route path="/register">
              <UserRegistration setError={notify} />
              </Route>
              <Route path="/forgetpassword">
                <ForgetPassword setError={notify} />
              </Route>
              <Route path="/newpassword/:id">
                <NewPassword setError={notify} />
              </Route>
              <Route path="/activation/:id">
                <UserActivation setError={notify}  />
              </Route>
              <Route path="/info">
                <Info  />
              </Route>
              <Route path="/termsofuse">
                <Termsofuse   />
              </Route>
              <Route path="/instructions">
                <Instructions   />
              </Route>
              <Route path="/login">
                {(token) ? <Redirect to="/" /> : <LoginForm setUsertype={setUsertype} onLogin={login} setError={notify} log={log} setLogout={setLogout} logout={0} setToken={setToken} client={client}/>
                }
              </Route>
              <Route path="/login/:id">
                {(log !== null) ? <Redirect to="/" /> : <LoginForm setUsertype={setUsertype} onLogin={login} setError={notify} log={log} setLogout={setLogout} logout={1} setToken={setToken} client={client}/>
                }
              </Route>
              <Route path="/logout">
                {(log !== null) ? <Redirect to="/" /> : <Logout setUsertype={setUsertype}  onLogin={login} setError={notify} log={log} setLogout={setLogout} logout={1} setToken={setToken} client={client}/>
              }  
              </Route>
              <Route path="/">
                <Home/>
                <br />
              </Route>
            </Switch>
            <br /><br /><br /><br />
            <Footer style={{zIndex:10}} />
          </Router>
        </Container>
        </div>
        </Suspense>
        </>
      )
}

export default App