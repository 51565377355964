import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'

import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, split } from '@apollo/client'
import { setContext } from 'apollo-link-context'

import { getMainDefinition } from '@apollo/client/utilities'
import { WebSocketLink } from '@apollo/link-ws'

//import * as serviceWorker from './serviceWorker'
import {I18nextProvider} from "react-i18next"
import i18next from "i18next"
import common_en from "./translations/en/common.json"
import common_fi from "./translations/fi/common.json"
import common_sv from "./translations/sv/common.json"

import './App.css'

import { Provider } from 'react-redux'
import configureStore from './store'
import * as serviceWorker from './serviceWorker'
//import 'bootstrap/dist/css/bootstrap.min.css'
import ReactGA from 'react-ga'

const store = configureStore()

i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'fi',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      fi: {
          common: common_fi
      },
      sv: {
        common: common_sv
    },
  },
});



function onChange(value) {
  console.log("Captcha value:", value);
}

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('lokos-user-token')
  return {
    headers: {
      ...headers,
      authorization: token ? `bearer ${token}` : null,
    }
  }
})
/*
const httpLink = new HttpLink({
  uri: 'http://localhost:3001/app',
})


const wsLink = new WebSocketLink({
  uri: 'ws://localhost:3001/app',
  options: {
    reconnect: true
  }
})
*/

const httpLink = new HttpLink({
  uri: 'https://lokosmaja.fi/app',
  //uri: 'https://kansalaiset.herokuapp.com/app',
})


const wsLink = new WebSocketLink({
  //uri: 'wss://kansalaiset.herokuapp.com',
  uri: 'wss://lokosmaja.fi',
  options: {
    reconnect: true
  }
})

/*
const httpLink = new HttpLink({
  uri: 'https://determined-tesla-37e09d.netlify.app/.netlify/functions/api',
})


const wsLink = new WebSocketLink({
  uri: 'ws://determined-tesla-37e09d.netlify.app/.netlify/functions/api',
  options: {
    reconnect: true
  }
})*/
 /*
const httpLink = new HttpLink({
  uri: 'https://daasback.herokuapp.com',
})


const wsLink = new WebSocketLink({
  uri: 'ws://daasback.herokuapp.com/graphql',
  options: {
    reconnect: true
  }
})
*/
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  authLink.concat(httpLink),
)

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: splitLink
})
ReactGA.initialize('UA-198353084-1')
//ReactGA.pageview(window.location.pathname + window.location.search)
ReactGA.pageview("/")

ReactDOM.render(
  <ApolloProvider client={client}>
    <I18nextProvider i18n={i18next}>
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
    </I18nextProvider>
  </ApolloProvider>, 
  document.getElementById('root')
)
/**
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById('root')
)
 */

/**import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
*/