import React, { useEffect, useState }from 'react'
import {useTranslation} from "react-i18next"
import {
  TextField,
  Button,
Select,
MenuItem
} from '@material-ui/core'
import {
  Link
} from "react-router-dom"
/*import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
*/
import Card from 'react-bootstrap/Card'
import ListGroup from 'react-bootstrap/ListGroup'

import kuva from '../pics/etu.png'
const Home = ({care,walking,ocare,owalking, walkers,handlers,animals }) => {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')
  const [municipal, setMunicipal] = useState('')
  const [type, setType] = useState('')
  const [animal, setAnimal] = useState('')
  const [vcare, setVcare] = useState(care)
  const [vwalking, setVwalking] = useState(walking)
  const [date, setDate] = useState('')
  const [system, setSystem] = useState('')
  const [domain, setDomain] = useState('')


  //ilmoituksen diville värin arpominen
  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }
  const divcolors=['green','red','#C1B1D1', 'grey', 'pink','#A1B1C1','#D1A1D1','#B1B1B1','#F1F1F1','#A1A1A1','#C1C1C1','#C1F1D1']
  

  if(type===''|| type==='kaikki'){
    //<p align='center' width='50%'><img src={kuva} width='100%' /></p>
      return(
        <div>
          <br /><br /><br />
      <div style={{width: '60%',margin:'5px', zIndex:11, border: '1px solid #12343b',borderRadius: '25px',
                    opacity: 0.99, backgroundColor:'#ffeee4', fontSize:'14px', top:'40%',fontFamily:'Helvetica', textAlign:'center' }}><br />
        <p><b>{t('welcome.text', {framework:'React'})}</b></p> 
        </div>

      <div style={{width: '60%',margin:'5px', zIndex:11, border: '1px solid #12343b',borderRadius: '25px',
                    opacity: 0.99, backgroundColor:'#ffeee4', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <b>Keittiö</b>
                    <ul>
                        Astianpesukone, liesi, liesituuletin, kahvinkeitin, mikroaaltouuni, leivänpaahdin, astiasto kuudelle, paistovälineet, jääkaappi pakastimella, pizzakivi, pizzalapio <br />
                    </ul>
      </div>
      <div style={{width: '60%',margin:'5px', zIndex:11, border: '1px solid #12343b',borderRadius: '25px',
                    opacity: 0.99, backgroundColor:'#ffeee4', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <b>Wc</b>
                    <ul>
                        Pyykinpesukone, bideesuihku, hiustenkuivaaja <br />
                    </ul>
      </div>
      <div style={{width: '60%',margin:'5px', zIndex:11, border: '1px solid #12343b',borderRadius: '25px',
                    opacity: 0.99, backgroundColor:'#ffeee4', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <b>Sauna</b>
                    <ul>
                        Sähkökiuas, suihku, laudepaikat neljälle <br />
                    </ul>
      </div>
      <div style={{width: '60%',margin:'5px', zIndex:11, border: '1px solid #12343b',borderRadius: '25px',
                    opacity: 0.99, backgroundColor:'#ffeee4', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <b>Alakerta</b>
                    <ul>
                        Takka, wc, tv, ilmalämpöpumppu, ruokapöytä neljälle, sohva kolmelle ja nojatuolit <br />
                    </ul>
      </div>
      <div style={{width: '60%',margin:'5px', zIndex:11, border: '1px solid #12343b',borderRadius: '25px',
                    opacity: 0.99, backgroundColor:'#ffeee4', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <b>Yläkerta</b>
                    <ul>
                        Sängyt neljälle, TV<br />
                    </ul>
      </div>
      <div style={{width: '60%',margin:'5px', zIndex:11, border: '1px solid #12343b',borderRadius: '25px',
                    opacity: 0.99, backgroundColor:'#ffeee4', fontSize:'14px', fontFamily:'Helvetica', textAlign:'center' }}><br />
      
                    <b>Muuta</b>
                    <ul>
                        Langaton nettiyhteys (Wifi), vene, purjelauta SUP-melalla, 3 aikuisten polkupyörää, 2 lasten polkupyörää, kaasugrilli (pitää ottaa omat kaasut mukaan), terassilla aurinkotuolit ja pöytä penkeillä kuudelle, tikkataulu, pelastusliivit<br />
                    </ul>
      </div>
      <br />
      <div>
    
        </div>
        
      </div>
    )
}
}
export default Home
