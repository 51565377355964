import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@material-ui/core"
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuIcon from "@material-ui/icons/Menu"
import HomeIcon from '@material-ui/icons/Home'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
import PanoramaIcon from '@material-ui/icons/Panorama'
import EventIcon from '@material-ui/icons/Event';
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import InsertCommentIcon from '@material-ui/icons/InsertComment'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import PeopleIcon from '@material-ui/icons/People'
import WorkIcon from '@material-ui/icons/Work'
import FeedbackIcon from '@material-ui/icons/Feedback'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import React, { useState, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import {useTranslation} from "react-i18next"
import ico from "../pics/icon.png"
import PetsIcon from '@material-ui/icons/Pets';
import DirectionsWalkSharpIcon from '@material-ui/icons/DirectionsWalkSharp';
import BugReportIcon from '@material-ui/icons/BugReport';
/**
 * 
 * 
 * Sand Tan: #e1b382

Sand Tan Shadow: #c89666

Night Blue: #2d545e

Night Blue Shadow: #12343b
 */
const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#e5c7bc",
    paddingLeft: "1%",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
    top: '85%',
    textAlign:'center'
    
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 6,
    color: "#000000",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 300,
    fontSize: "10px",
    size: "10px",
    marginLeft: "0px",
    color: "#692705"
    //"#183a1d",
    //"#f0a04b",
  },
  toolbar: {
    width: '500px',
    display: "flex",
    justifyContent: "space-between",
    borderRadius: '16px',
    textAlign:'right',
    zIndex:'10',
    bottom: '0px'
  },
  drawerContainer: {
    padding: "1px 1px",
  },
}));

export default function Headerpublic() {
  // eslint-disable-next-line
  const [t, i18n] = useTranslation('common')

  const { header, logo, menuButton, toolbar, drawerContainer } = useStyles();
  
const headersData = [
  {
    label: 'Lokosmaja',
    href: "/",
    icon: <HomeIcon />
  },
  {
    label: t('menu.activities'),
    href: "/activities",
    icon: <DirectionsWalkSharpIcon />
  },
  {
    label: t('menu.calendar'),
    href: "/booking",
    icon: <EventIcon />
  },
  {
    label: t('menu.prices'),
    href: "/prices",
    icon: <MonetizationOnIcon />
  },
  {
    label: t('menu.pictures'),
    href: "/pics",
    icon: <InsertPhotoIcon />
  },
  {
    label: t('menu.panorama'),
    href: "/panorama",
    icon: <PanoramaIcon />
  },
  {
    label: t('menu.video'),
    href: "/videos",
    icon: <VideoLibraryIcon />
  },
  {
    label: t('menu.contact'),
    href: "/contact",
    icon: <ContactMailIcon />
  },
];

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false,
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();

    window.addEventListener("resize", () => setResponsiveness());
  }, []);

  const displayDesktop = () => {
    return (
      <Toolbar sx={{ borderRadius: '5px' }} className={toolbar}>
        <Link
          {...{
            component: RouterLink,
            to: '/',
            style: { textDecoration: "none" },
          }}
        >
        </Link>
        <div>{getMenuButtons()}</div>
      </Toolbar>
    );
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <Toolbar>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen,
          }}
        >
         <div style={{position: 'absolute', left: '10px',color: 'white'}  }><MenuIcon /></div> 
        </IconButton>
        <Link
          {...{
            component: RouterLink,
            to: '/',
            style: { textDecoration: "none" },
          }}
        >

          <div style={{position: 'absolute', left: '4%', top: '1450px',color: '#e1b382', fontSize: '20px', fontFamily:'Arial black' }  }>Lokosmaja</div>
        </Link>
        <Drawer
          {...{
            anchor: "left",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>

        <div style={{position: 'absolute',}  }>
        <IconButton
          {...{
            color: "inherit",
            to: '/login',
            component: RouterLink,
            className: menuButton,
            size: "small"
          }}
        >

        </IconButton></div>
      </Toolbar>
    );
  };

  const getDrawerChoices = () => {
    return headersData.map(({ label, href, icon }) => {
      return (
        <Link
          {...{
            component: RouterLink,
            to: href,
            color: "inherit",
            style: { textDecoration: "none" },
            key: label,
          }}
        >
          <MenuItem>{label}</MenuItem>
        </Link>
      );
    });
  };

  //<HomeIcon fontSize="large" color="action" alt='Lokosmaja Siikaisten Eteläpäässä'/>

  const DaaSLogo = (
    <div>
    
    </div>
  );

  const getMenuButtons = () => {
    return headersData.map(({ label, href, icon }) => {
      return (
        <IconButton
          {...{
            key: label,
            color: "inherit",
            to: href,
            component: RouterLink,
            className: menuButton,
            size: "small"
          }}
        >
          <table>
          <tr><td>{icon}</td></tr><tr><td>{label}</td></tr>
          </table>
        </IconButton>
      );
    });
  };
//{mobileView ? displayMobile() : displayDesktop()}
  return (
    <header>
      <AppBar className={header}>
        {displayDesktop()}
      </AppBar>
    </header>
  );
}