import {
  AppBar,
  Toolbar,
  Typography,
  makeStyles,
  IconButton,
  Drawer,
  Link,
  MenuItem,
} from "@material-ui/core"
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MenuIcon from "@material-ui/icons/Menu"
import HomeIcon from '@material-ui/icons/Home'
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto'
import PanoramaIcon from '@material-ui/icons/Panorama'
import EventIcon from '@material-ui/icons/Event';
import AccountTreeIcon from '@material-ui/icons/AccountTree'
import InsertCommentIcon from '@material-ui/icons/InsertComment'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import PeopleIcon from '@material-ui/icons/People'
import WorkIcon from '@material-ui/icons/Work'
import FeedbackIcon from '@material-ui/icons/Feedback'
import ContactMailIcon from '@material-ui/icons/ContactMail'
import VpnKeyIcon from '@material-ui/icons/VpnKey'
import React, { useState, useEffect } from "react"
import { Link as RouterLink } from "react-router-dom"
import {useTranslation} from "react-i18next"
import ico from "../pics/icon.png"
import PetsIcon from '@material-ui/icons/Pets';
import DirectionsWalkSharpIcon from '@material-ui/icons/DirectionsWalkSharp';
import BugReportIcon from '@material-ui/icons/BugReport';
/**
 * 
 * 
 * Sand Tan: #e1b382

Sand Tan Shadow: #c89666

Night Blue: #2d545e

Night Blue Shadow: #12343b
 */
const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: "#e5c7bc",
    paddingRight: "0px",
    paddingLeft: "0px",
    "@media (max-width: 900px)": {
      paddingLeft: 0,
    },
    top: '85%',
    textAlign:'center'
    
  },
  logo: {
    fontFamily: "Work Sans, sans-serif",
    fontWeight: 600,
    color: "#000000",
    textAlign: "left",
  },
  menuButton: {
    fontFamily: "Open Sans, sans-serif",
    fontWeight: 300,
    fontSize: "10px",
    size: "10px",
    marginLeft: "0px",
    color: "#692705"
    //"#183a1d",
    //"#f0a04b",
  },
  toolbar: {
    width: '700px',
    display: "flex",
    justifyContent: "space-between",
    borderRadius: '16px',
    textAlign:'center',
    zIndex:'10',
    bottom: '0px'
  },
  drawerContainer: {
    padding: "10px 10px",
  },
}));

export default function Banner() {
  // eslint-disable-next-line
  
  return (
    <div style={{width: '85%',padding:'10px',margin:'5px',fontFamily: 'Helvetica',  zIndex:1, left:'0px', position:'fixed', top:'10px', height:'80px',
    opacity: 1,border: '0px solid #12343b', fontSize:'40px',borderRadius: '5px', textAlign:'left', color:'#0f0f0f' }} >
       Lokosmaja
       </div>
  );
}