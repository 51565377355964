import React, { useState } from 'react';
import {useTranslation} from "react-i18next"
const Prices = ({}) => {
    
            const [availability, setAvailability] = useState([])
            const [t, i18n] = useTranslation('common')
            return ( 
                <><br/><br />          
                <div style={{ 
        padding:'10px',margin:'5px', zIndex:11, width: '60%', left:'10px', top:'100px',
        opacity: 1, backgroundColor:'#ffeee4',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'
      }}>
                 <b>{t('common.list1000', {framework:'React'})}</b><br />
                 <ul><li>{t('common.list1001', {framework:'React'})}</li>
                 <li>{t('common.list1002', {framework:'React'})}</li>
                 <li>{t('common.list1003', {framework:'React'})}</li>
                 <li>{t('common.list1004', {framework:'React'})}</li>
                 <li>{t('common.list1005', {framework:'React'})}</li>
                 <li>{t('common.list1006', {framework:'React'})}</li>
                 </ul>
               </div>
               <div style={{ 
        padding:'10px',margin:'5px', zIndex:11, width: '60%', left:'200px', top:'100px',
        opacity: 1, backgroundColor:'#ffeee4',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'
      }}>
                 <b>{t('common.list1007', {framework:'React'})}</b><br />
                 <ul><li>{t('common.list1008', {framework:'React'})}</li>
                    <li>{t('common.list1009', {framework:'React'})}</li>
                    <li>{t('common.list1010', {framework:'React'})}</li>
                    <br />
                 </ul>
                 </div>
                 <div style={{ 
        padding:'10px',margin:'5px', zIndex:11, width: '60%', left:600,
        opacity: 1, backgroundColor:'#ffeee4',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'
      }}>
                 <b>{t('common.list1011', {framework:'React'})}</b><br />
                 <ul><li>{t('common.list1012', {framework:'React'})}</li>
                     <li>{t('common.list1013', {framework:'React'})}</li>
                    <li>{t('common.list1014', {framework:'React'})}</li>
                    <li>{t('common.list1015', {framework:'React'})}</li>
                    <br />
                 </ul>
                 </div>
                 <div style={{ 
        padding:'10px',margin:'5px', zIndex:11, width: '60%', left:10,
        opacity: 1, backgroundColor:'#ffeee4',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left'
      }}>
                <b>{t('common.list1016', {framework:'React'})}</b><br />
                <ul>
                    <li><a href='/booking'>{t('common.list1017', {framework:'React'})}</a></li>
                    <li>{t('common.list1018', {framework:'React'})}</li>
                    <li>{t('common.list1019', {framework:'React'})}</li>
                    <li>{t('common.list1020', {framework:'React'})}</li>
                 </ul>
                </div>
                </>
            );
    }
    export default Prices
