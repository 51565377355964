import React, { useState } from 'react';

const Booking = ({}) => {
    
            const [availability, setAvailability] = useState([])
            /**<b>Tee varaus</b>
                <br /><br />
                Etunimi<br /><input  type="text" name="Etunimi" placeholder="First Name" required /><br />
				        Sukunimi<br/>
				        <input  type="text" name="Sukunimi" placeholder="Surname" required/><br/>
				
						S&auml;hk&ouml;posti<br/>
						<input  type="email" name="SÃ¤hkÃ¶posti" placeholder="Email Address" required/><br />
						Puhelinnumero<br />
						<input  type="text" name="Puhelinnumero" size="20" placeholder="Phone Number" required/><br />
				
						Osoite<br />
						<input  type="text" name="Katuosoite" placeholder="Address" required/><br/>
				    
						Postinumero ja -toimipaikka<br/>
						<input  type="text" name="Postitoimipaikka" placeholder="Postal number" required/><br />
				Varauksen kesto<br/>
	
						Saapumisp&auml;iv&auml; (klo 15 alkaen)
						<input  type="date" name="AlkamispÃ¤ivÃ¤" id="alkamispv" required/><br/>
				    
						L&auml;ht&ouml;p&auml;iv&auml; (klo 12 asti)
						<input type="date" name="LoppumispÃ¤ivÃ¤" id="loppumispv" required/><br/><br/>
                        Lisätiedot<br />
                        <textarea name="lisainfo" placeholder="Infos" required/><br />
				<br/>
				<input  type="submit" value="L&auml;het&auml;"/>
				<input  type="reset" value="Tyhjenn&auml;"/>
                </div> */
            
            return (            
                <div>  
                    <br /><br /> 
                    <div style={{width: '500px',padding:'10px',margin:'5px', zIndex:11,        left:10,
                    opacity: 1, backgroundColor:'white',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left' }} >
                        <br />
                        <br />
                        <iframe style={{width: '100%',  height: '600px'}}  src='https://calendar.google.com/calendar/embed?height=600&wkst=2&bgcolor=%23e5f89a&ctz=Europe%2FHelsinki&showTitle=0&hl=fi&src=dGFoZG9uLmtvZGluQGdtYWlsLmNvbQ&src=YTQ0MjFkZWM3NDdlY2RjZDYxOWQ5ZTIzMTY5YWNlYjQ1Yzk2ZGJkNmE0NDA0M2Q4YmEyYWFiMzY3MWZkOGZiMkBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=NzI3YmZhZTgzODhmMDBlYjUyNmVlNzU2MjQ3MzcwYmIwZWU3YjRkZjFjMWQyNDQwZWU1MDNjOTQwNDNkMjQ5MEBncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=ZmYwNmUwOTNiNDJhOWQ4MDRjYTE3ODliOTM2MTFlOTVhNThhMzIxMWVlYTA4ZjdjOGI0OWMxNTVhNmMyMjUxY0Bncm91cC5jYWxlbmRhci5nb29nbGUuY29t&src=YWRkcmVzc2Jvb2sjY29udGFjdHNAZ3JvdXAudi5jYWxlbmRhci5nb29nbGUuY29t&src=ZmkuZmlubmlzaCNob2xpZGF5QGdyb3VwLnYuY2FsZW5kYXIuZ29vZ2xlLmNvbQ&color=%23039BE5&color=%23616161&color=%233F51B5&color=%23C0CA33&color=%2333B679&color=%230B8043'>loading...</iframe>
                    </div>
        
                    <br />
                    <div style={{width: '500px', padding:'10px',margin:'5px', zIndex:11, left:10,
                    opacity: 1, backgroundColor:'white',border: '1px solid #12343b', fontSize:'14px', fontFamily:'Helvetica',borderRadius: '25px', textAlign:'left' }}> 
                        <iframe style={{ width: '100%', height: '2500px' }} src="https://docs.google.com/forms/d/e/1FAIpQLSe0TPoW9QOKdQTA2vLlXUUBde1IxEUOgZGAZqGzh8ShHypjeA/viewform?embedded=true">Loading...</iframe>
                    </div>
                </div>
            );
    }
    export default Booking
