import React, { useState } from 'react';

const Pics = ({}) => {
    
            const [availability, setAvailability] = useState([])
            return (               
               <></> 
            );
    }
    export default Pics
